/* Setting custom fonts */
@font-face {
	font-family: Centra;
	src: url(./assets/font/CentraNo2-Bold.ttf);
	font-weight: 700;
}

@font-face {
	font-family: Centra;
	src: url(./assets/font/CentraNo2-Medium.ttf);
	font-weight: 500;
}

@font-face {
	font-family: Centra;
	src: url(./assets/font/CentraNo2-Book.ttf);
	font-weight: 400;
}

/* Default css */
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
	scroll-padding-top: 75px;
}

body {
	font-weight: 400;
	overflow-x: hidden;
	position: relative;
	background-color: #121212 !important;
	color: #fff !important;
	font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	padding: 0;
	line-height: 0;
}

p,
a,
li,
button,
ul {
	margin: 0;
	padding: 0;
	line-height: normal;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

img {
	width: 100%;
	height: auto;
}

button {
	border: 0;
	background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
	outline: none;
}

@media (min-width: 1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}

/* ========== Changing react-bootstrap css styles ========== */

/* Navbar element */
nav.navbar {
	padding: 18px 0;
	position: fixed;
	width: 100%;
	top: 0;
	z-index: 9999; /* Ensure that navbar is the top element that gets displayed in cases of overlapping elements */
	transition: 0.32s ease-in-out;
}

/* scrolled class */
nav.navbar.scrolled {
	padding: 10px 0;
	background-color: #121212;
}

/* Navbar.brand element */
nav.navbar a.navbar-brand {
	width: 9%;
}

/* Navbar.link element */
nav.navbar .navbar-nav .nav-link.navbar-link {
	font-weight: 400;
	color: #fff !important;
	letter-spacing: 0.8px;
	padding: 0 25px;
	font-size: 18px;
	opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
	opacity: 1;
}

/* Navbar icons */
span.navbar-socials {
	display: flex;
	align-items: center;
}

/* Navbar social icons */
.social-icon {
	display: inline-block;
	margin-left: 14px;
}

.social-icon a {
	background: rgba(217, 217, 217, 0.1);
	border: 1px solid rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	margin-right: 6px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: 42px;
	height: 42px;
	line-height: 1;
}

/* Animation for social icons */
.social-icon a::before {
	content: "";
	width: 42px;
	height: 42px;
	position: absolute;
	background-color: #ffffff;
	border-radius: 50%;
	transform: scale(0);
	transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
	transform: scale(1);
}
.social-icon a img {
	width: 40%;
	z-index: 1;
	transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
	filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
		hue-rotate(346deg) brightness(95%) contrast(86%);
}

/* Let's connect button */
.navbar-socials button {
	font-weight: 700;
	color: #fff;
	background-color: transparent;
	border: 1px solid #fff;
	padding: 18px 34px;
	font-size: 18px;
	margin-left: 18px;
	position: relative;
	transition: 0.3s ease-in-out;
}
.navbar-socials button span {
	z-index: 1;
}
.navbar-socials button::before {
	content: "";
	width: 0%;
	height: 100%;
	position: absolute;
	background-color: #fff;
	left: 0;
	top: 0;
	z-index: -1;
	transition: 0.3s ease-in-out;
}
.navbar-socials button:hover {
	color: #121212;
}
.navbar-socials button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

/* Navbar toggler icon for mobile */
nav.navbar .navbar-toggler-icon {
	width: 24px;
	height: 17px;
	background-image: none;
	position: relative;
	border-bottom: 2px solid #fff;
	transition: all 300ms linear;
	top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: "";
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon::after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}

/* ========== Banner Styling ========== */
.banner {
	margin-top: -3rem;
	padding: 225px 0 100px 0;
	background-image: url("./assets/img/banner-bg.png");
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	text-align: justify;
}

.banner .tagline {
	font-weight: 700px;
	letter-spacing: 0.8px;
	padding: 8px 10px;
	background: linear-gradient(
		90.21deg,
		rgba(170, 54, 124, 0.5) -5.91%,
		rgba(74, 47, 189, 0.5) 111.58%
	);
	border: 1px solid rgba(255, 255, 255, 0.5);
	font-size: 20px;
	margin-bottom: 16px;
	display: inline-block;
}

.banner h1 {
	font-size: 65px;
	font-weight: 700px;
	letter-spacing: 0.8px;
	line-height: 1;
	margin-bottom: 20px;
	display: block;
	/* margin-top: 75px; */
}

.banner .dynamic-text {
	display: block;
	font-size: 25px;
	font-weight: 500;
	margin-top: 15px;
}

.banner p {
	color: #b8b8b8;
	font-size: 18px;
	letter-spacing: 0.8px;
	line-height: 1.5em;
	width: 96%;
}

.banner button {
	color: #fff;
	font-weight: 700;
	font-size: 20px;
	margin-top: 40px;
	display: flex;
	align-items: center;
	/* margin-left: 10px; */
}

.banner button svg {
	font-size: 25px;
	margin-left: 10px;
	transition: 0.3s ease-in-out;
	line-height: 1;
}

.banner button:hover svg {
	margin-left: 25px;
}
.banner img {
	max-width: 28rem;
	margin-left: 3.5rem;
	animation: updown 3s linear infinite;
}
@keyframes updown {
	0% {
		transform: translateY(-20px);
	}
	50% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-20px);
	}
}
.txt-rotate > .wrap {
	border-right: 0.08em solid #666;
}

/* ========== Skills section styling ========== */
.skill {
	padding: 0 0 50px 0;
	position: relative;
}

.skill-container {
	background: #151515;
	border-radius: 64px;
	text-align: center;
	padding: 60px 50px;
	margin-top: -60px;
}

.skill h2 {
	font-size: 45px;
	font-weight: 700;
}

.skill p {
	color: #b8b8b8;
	font-size: 18px;
	line-height: 1.5rem;
	margin: 14px 0 75px 0;
}

.skill-slider {
	width: 80%;
	margin: 0 auto;
	position: relative;
}

.skill-slider .item img {
	width: 50%;
	margin: 0 auto 15px auto;
}

.background-image-left {
	top: 28%;
	position: absolute;
	bottom: 0;
	width: 40%;
	z-index: -4;
}

/* Skillsets section (Alternative layout) */
.skillsets {
	padding: 0 0 50px 0;
	position: relative;
}

.skillsets-container {
	background: #151515;
	border-radius: 64px;
	text-align: center;
	padding: 60px 50px;
	margin-top: -60px;
}

.skillsets h2 {
	font-size: 45px;
	font-weight: 700;
	margin-bottom: 2rem;
}

/* Add media query (change 5.5rem to 32px) */
.custom-grid {
	display: grid;
	grid-template-columns: repeat(auto-fill, 5.5rem);
	justify-content: space-between;
	position: relative;
	width: 80%;
	margin: 0 auto;
}

.custom-grid img {
	width: 3.5rem;
	height: 3.5rem;
	margin: 0 auto 15px auto;
}

/* Popup text on hover */
.tooltip > .tooltip-inner {
	border: #fff solid 1px;
	font-weight: 700;
}

.tooltip > .tooltip-arrow {
	display: none !important;
}

/* ========== Experience styling ========== */
.experience {
	padding: 80px 0 48px 0;
	position: relative;
	/* background-color: black; */
}

.experience h2 {
	font-size: 45px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 2rem;
}

.experience .nav.nav-pills {
	width: 72%;
	margin: 0 auto;
	border-radius: 50px;
	background-color: rgb(255 255 255 / 10%);
	overflow: hidden;
}
.experience .nav.nav-pills .nav-item {
	/* width: 33.33333%; */
	width: 50%;
}
.experience .nav.nav-pills .nav-link {
	background-color: transparent;
	border-radius: 0;
	padding: 17px 0;
	color: #fff;
	width: 100%;
	font-size: 17px;
	letter-spacing: 0.8px;
	font-weight: 500;
	position: relative;
	transition: 0.3s ease-in-out;
	text-align: center;
	z-index: 0;
}
/* color for each sub-tab */
.experience .nav.nav-pills .nav-link::before {
	content: "";
	position: absolute;
	width: 0;
	height: 100%;
	background: linear-gradient(90.21deg, #aa367c -5.91%, #4a2fbd 111.58%);
	top: 0;
	left: 0;
	z-index: -1;
	transition: 0.3s ease-in-out;
}
.experience .nav.nav-pills .nav-link.active::before {
	width: 100% !important;
}

.background-image-right {
	top: 20%;
	position: absolute;
	bottom: 0;
	width: 35%;
	right: 0;
	z-index: -4;
}

.timeline {
	margin: 50px auto;
}

.timeline-title {
	font-weight: 500;
	font-size: 25px;
	text-align: center;
}

.timeline-source {
	font-weight: 400;
	font-size: 20px;
	margin-bottom: -5px;
	text-align: center;
	white-space: pre-line;
	line-height: 30px;
}

.timeline-desc-list {
	list-style: none;
	text-align: justify;
	padding: 0;
	margin: 16px 0.8rem 0;
}

.timeline-desc-list li {
	color: #b8b8b8;
	font-size: 16px;
	letter-spacing: 0.8px;
	line-height: 1.6em;
	margin-top: 0.4rem;
	font-weight: 400;
}

.timeline-desc-list li:nth-child(n + 2) {
	margin-top: 0.4rem;
}

.timeline-desc-list li::before {
	content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
	color: #b8b8b8; /* Change the color */
	font-weight: bold; /* If you want it to be bold */
	display: inline-block; /* Needed to add space between the bullet and the text */
	width: 1em; /* Also needed for space (tweak if needed) */
	margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.vertical-timeline-element-icon {
	display: flex;
	justify-content: center;
	align-items: center;
}
.icon-img {
	max-width: 65%;
	object-fit: contain;
}
.icon-img:hover {
	cursor: pointer;
}
.icon-img-change {
	background: white;
	border: 2px solid white;
	border-radius: 30%;
}

.techStack-container {
	display: flex;
	float: left;
	width: 100%;
	margin: 0.8rem 0 0 0.5em;
}

.techStack-container img {
	width: 1.5rem;
	height: 1.5rem;
}

.techStack-icon:nth-child(n + 2) {
	margin-left: 0.6rem;
}

.timeline-date {
	float: left;
	padding-left: 0.65em;
	margin-top: 0.8rem !important;
	color: #b8b8b8;
	font-size: 14px !important;
	font-weight: 700;
	font-style: italic;
}

.footer {
	font-weight: 400;
	font-size: 16px;
	text-align: center;
	color: #b8b8b8; /* Change the color */
}

/* ========== Media Queries ========== */

@media (max-width: 1169px) {
	.experience .nav.nav-pills {
		width: 100%;
	}
}

@media (max-width: 991px) {
	nav.navbar a.navbar-brand {
		display: none;
	}
	nav.navbar .navbar-nav {
		flex-direction: row;
	}
	.banner h1 {
		font-size: 3.2rem;
	}
	.banner .dynamic-text {
		font-size: 1.2rem;
	}
	.banner p {
		font-size: 1rem;
	}
	.skillsets h2 {
		font-size: 2.6rem;
	}
	.experience h2 {
		font-size: 2.6rem;
	}
	.experience .nav.nav-pills {
		width: 100%;
	}
	.custom-grid {
		grid-template-columns: repeat(auto-fill, 4rem);
	}
}

@media (max-width: 767px) {
	nav.navbar .navbar-nav .nav-link.navbar-link {
		padding: 0 15px;
	}
	.banner h1 {
		margin-top: 0;
		font-size: 2.5rem;
		text-align: center;
	}
	.banner .dynamic-text {
		font-size: 1rem;
	}
	.banner p {
		font-size: 0.875rem;
		margin: 0 auto;
	}
	.banner img {
		max-width: 14rem;
		margin: 0rem auto 0 auto;
	}
	.banner button {
		font-size: 1rem;
		margin-top: 1rem;
	}
	.banner button svg {
		width: 1rem;
		height: 1rem;
	}
	.skillsets-container {
		padding: 60px 0;
	}
	.custom-grid img {
		width: 2rem;
		height: 2rem;
	}
	.skillsets h2 {
		font-size: 2rem;
	}
	.custom-grid {
		grid-template-columns: repeat(auto-fill, 2rem);
	}
	.experience h2 {
		font-size: 2rem;
	}
	.experience .nav.nav-pills .nav-link {
		font-size: 0.875rem;
	}
	.timeline-desc-list li {
		font-size: 0.875rem;
	}
}

@media (max-width: 575px) {
	.banner p {
		width: 85%;
	}
}

@media (max-width: 533px) {
	.timeline-title {
		font-size: 1.4rem;
	}
	.timeline-source {
		font-size: 1.1rem;
	}
	.timeline-desc-list li {
		font-size: 1rem;
	}
}

@media (max-width: 491px) {
	nav.navbar .navbar-nav .nav-link.navbar-link {
		padding: 0 10px;
		font-size: 1rem;
	}
	nav.navbar .social-icon a {
		width: 36px;
		height: 36px;
	}
	.social-icon a::before {
		width: 36px;
		height: 36px;
	}
	.timeline-title {
		font-size: 1.3rem;
	}
	.timeline-source {
		font-size: 1rem;
	}
	.timeline-desc-list li {
		font-size: 0.875rem;
	}
}

@media (min-width: 360px) and (max-width: 425px) {
	nav.navbar .navbar-nav .nav-link.navbar-link {
		padding: 0 6px;
	}
	nav.navbar .social-icon a {
		margin: 0;
		width: 35px;
		height: 35px;
		margin-right: 0.25rem;
	}
	.social-icon a::before {
		width: 35px;
		height: 35px;
	}
}

.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
